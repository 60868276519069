<template>
  <div id="tournaments">
    <h1>Turnier</h1>
    <div id="tournamentAdd">
      <div class="textField">
        <label>Event</label>
        <select v-model="selectedEvent">
          <option disabled value=0> Event auswählen</option>
          <option v-for="event in events" :key="event.event_id" :value="event.event_id"> {{ event.event_name }}</option>
        </select>
      </div>
      <div class="textField">
        <label>Game</label>
        <select v-model="selectedGame">
          <option disabled value=0> Game auswählen</option>
          <option v-for="game in games" :key="game.game_id" :value="game.game_id"> {{ game.game_name }}</option>
        </select>
      </div>
      <div class="textField">
        <label>Titel</label>
        <input v-model="title">
      </div>
      <div class="textField">
        <label>Preis</label>
        <input v-model="prize">
      </div>
      <div class="textField">
        <label>Ingame Name Source</label>
        <input v-model="ingame_name_source">
      </div>
      <div class="textField">
        <label>Datum</label>
        <input v-model="date" type="date">
      </div>
      <div class="textField">
        <label>Uhrzeit</label>
        <input v-model="time" type="time">
      </div>
      <PlainButton :button-function="createNewTournament" :is-slim="true" :scale-by-child="true" :is-small="true">
        Turnier hinzufügen
      </PlainButton>
    </div>
    <SuccessPop :error-text="alertMessage" v-if="alertType==='alert-success'"></SuccessPop>
    <div v-for="event in events" :key="event.event_id" class="eventContainer">
      <h3>{{ event.event_name }}</h3>
      <table>
        <tr>
          <th>Title :</th>
          <th>Game:</th>
          <th> Team Size:</th>
          <th>Preis</th>
          <th>Registrierung offen</th>
          <th>Datetime</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="tournament in event.tournaments" :key="tournament.id">
          <td>{{ tournament.title }}</td>
          <td>{{ tournament.game.game_name }}</td>
          <td>{{ tournament.game.team_size }}</td>
          <td>{{ tournament.prize }}</td>
          <td>{{ tournament.is_registration_open }}</td>
          <td>{{ formatDate(new Date(tournament.datetime)) }}</td>
          <td>
            <PlainButton button-type="success" is-slim is-small :button-function="()=>openTournament(tournament.tournament_id,false)">
              Öffnen
            </PlainButton>
          </td>
          <td>
            <PlainButton button-type="cancel"  is-slim is-small :button-function="()=>openTournament(tournament.tournament_id,true)">
              Schließen
            </PlainButton>
          </td>
        </tr>
      </table>
    </div>
    <ErrorPop :error-text="alertMessage" v-if="alertType==='alert-danger'"></ErrorPop>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {adminService} from "@/services";
import ErrorPop from "@/components/Popups/ErrorPop";
import SuccessPop from "@/components/Popups/SuccessPop";
import PlainButton from "@/components/Buttons/PlainButton";

export default {
  name: "Game",
  components: {PlainButton, ErrorPop, SuccessPop},
  data() {
    return {
      selectedGame: 0,
      selectedEvent: 0,
      date: "",
      time: "",
      title:"",
      prize: "",
      games: [],
      turniere: [],
      ingame_name_source: '',

    }
  },
  computed: {
    ...mapState('alert', {
      alertMessage: 'message',
      alertType: 'type'
    }),
    dateTime() {

      let dateString = this.date + "T" + this.time + ":00Z"

      return new Date(dateString);
    }
  },
  created() {
    this.fetchGames();
    this.fetchEvents();
  },
  methods: {
    ...mapActions("alert", ['success', "error"]),
    createNewTournament() {

      adminService.addTournament({
        'game_id': this.selectedGame,
        'event_id': this.selectedEvent,
        'title':this.title,
        'prize':this.prize.length>0?this.prize:null,
        'datetime': this.dateTime.toISOString(),
        'ingame_name_source': this.ingame_name_source,
      }).then(
          event => {
            this.success('Game Added: ' + event.event_name);
            this.fetchEvents()
          },
          error => {
            this.error(Array.isArray(error) ? error[0].msg : error)
          }
      )
    },
    fetchEvents() {
      adminService.fetchEvents().then(events => {
        this.events = events
      })
    },
    fetchGames() {
      adminService.fetchGames().then(games => {
        this.games = games
      })
    },
    formatDate(date) {
      return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getUTCFullYear() + " - " + ((date.getUTCHours() + 1) % 24) + ":" + ('0' + date.getUTCMinutes()).slice(-2)
    },
    openTournament(id, isOpen){
      adminService.toggleTournament(id,isOpen).then(
          tournamentStatus => {
            this.success('Tournament Status Changed: ' + tournamentStatus.is_registration_open?'Open':'Closed');
            this.fetchEvents()
          },
          error => {

            this.error(Array.isArray(error) ? error[0].msg : error)
          }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/themes";

#tournaments {
  h3 {
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 8px;
    @include theme() {
      color: theme-get('header-3');

    }
  }

  .eventContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  table {
    @include theme() {
      color: theme-get('text-1');

    }
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 16px;
      border: none;
      @include theme() {
        color: theme-get('text-2');

      }
      outline: none;
    }

    th {
      padding: 16px;
      @include theme() {
        background-color: theme-get('primary-color');

      }
    }

    tr:nth-child(2n+1) {
      @include theme() {
        background-color: theme-get('background-color-1');

      }
    }

    @include theme() {
      background-color: theme-get('background-color-2');

    }


  }

  div {
    width: 200px;
    display: flex;
    flex-direction: row;

  }

  #tournamentAdd {
    gap: 16px;

    margin-bottom: 64px;
  }

  .textField {
    input {
      width: 200px;
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  padding: 32px 64px;

  h1 {
    font-size: 3em;
    @include theme {
      color: theme-get('header-1')
    }
  }

  label {
    @include theme {
      color: theme-get('text-1')
    }
  }
  text-align: left;
}
</style>